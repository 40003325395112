import React from "react";
import MinimizedHeader from "../../Components/EventPageComp/Js/minimizedHeader";

export default function TermOfUse() {
  return (
    <div>
      <MinimizedHeader />
      <div style={{ padding: "2% 17%" }}>
        {" "}
        <p className="Section-Dates">Tuesday, December 31, 2024</p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img
            src="UULogoResized.jpg"
            alt="Logo"
            style={{ height: "50%", width: "50%" }}
          />
          <h1 style={{ fontSize: "15px" }}>_( httP-S: //u rbanuP-date. in)_</h1>
          <h1 className="Section-Titles" style={{ fontSize: "25px" }}>
            Term of use
          </h1>
          {/* prettier-ignore */}
          <div style={{ textAlign: "justify", lineHeight: "1.6" }}>
          <p style={{ whiteSpace: "pre-wrap" }} className="Section-Text">
            All India Institute of Local-Self Government (AIILSG) is the sole and absolute owner of &amp; maintains www.urban update.in, and
            other mobile applications (Apps) to enhance public access to the urban update magazine owned by the AIILSG. This service is
            provided on an &#39;&#39;As Is&quot; basis &amp; is continually under development.
            In these terms of service we will refer to our company as &quot;Urban Update;&#39; &quot;we,&quot; or &quot;us.&quot;
            These are the terms by which you may use our websites and/or download and our App and use our service. You are
            authorized to use our website, app, and our service only if you agree that these terms bind you, and your use of the
            Website, App or service constitutes your acceptance of this agreement
            We have posted our Privacy Policy elsewhere. You can find it at urban update.in Privacy Policy is committed to providing a
            website that is accessible to the widest possible audience, regardless of technology or ability. We are actively working to
            increase the accessibility and usability of our website and in doing so adhere to many of the available standards and
            guidelines.<br></br>
            This website endeavours to conform to level Double-A of the World Wide Web Consortium (W3C) Web Content Accessibility
            Guidelines 2.0.This site has been built using code compliant with W3C standards for HTML and CSS. The site displays correctly in
            current browsers and using standards compliant HTML/CSS code means any future browsers will also display it correctly.
            Whilst www.urban update.in strives to adhere to the accepted guidelines and standards for accessibility and usability, it is not
            always possible to do so in all areas of the website .<br></br>
            Copyrights: Except as expressly stated in this site, no part of this website, including information, images, logos, photos, and
            overall appearance of the site, may be copied, republished, broadcast or reproduced in any form whatsoever without the prior
            written permission of the copyright holders other than for your own personal or non-commercial use.<br></br>
            AIILSG grants you a limited licence to access and make personal use of this site but not for commercial purposes. We permit the use of
            materials on this website subject to due credit being given to AIILSG. The use shall also be subject to non-commercial use and limited
            to personal or academic dissemination (including on social media and use on all kinds of media) .<br></br>
            Copyright should be acknowledged. Such use should not infringe on the Intellectual Property Rights of any person. When
            using material on this website, proper attribution to authors and/or copyright holders must be given. User is granted a
            limited, revocable and non-exclusive right to create hyperlinks to the home page or any other page of Urban Update so
            long as any of these links do not portray Urban Update or their podcast or products or services in a false, misleading,
            derogatory or offensive manner. You may not use Urban Update logos or proprietary graphics or trademarks as part of the
            Home(httP-s://urbanuP-date. inl) News(httP-s://urbanuP-date.in/catego[Y./newsl) . COVID-
            19(httP-s://urba
            link without express permission.You may not use the website, app or services, and you agree not to use them, if we have given
            you notice (by email to the last email address you furnished us) that we have terminated your privilege of using them.
            Your Contributions to Our Service: You agree that we have a right to use any content or information that you contribute to our
            service in any way as we see fit. You specifically grant us an irrevocable, perpetual, royaltyfree license to do the following
            throughout the world: to exercise all copyright rights with respect to your contributions, including republishing them; to attribute
            <br></br>
            your contributions to you publicly, including in advertising or promotion; and to sublicense others to do the same things you
            have licensed us to do.<br></br>
            You also agree that we have no obligation to accept, maintain, or preserve any of your contributions to our service. We do
            not provide storage or archive service for user contributed content.<br></br>
            Specific Terms for App: Subject to agreement to, and your compliance with, these terms, we grant you a limited non exclusive,
            non-transferable, non-sublicenseable license to download and install a copy of the app on a mobile device or computer that you
            own or control and to run such copy of the app solely for your own personal, non-commercial purposes. We reserve all rights in,
            and to, the app not expressly granted to you under these terms.<br></br>
            You agree that you shall not own any rights in the app but merely have a license to possess and use the app in conformity
            with these terms. You promise that you shall not:<br></br>
            copy, modify, or create derivative works based on the app; distribute, transfer, sublicense, lease, lend, or rent the app or any
            copy of the app to any third party; reverse engineer, decompile or disassemble the App; or make the functionality of the app
            available to multiple users through any means.<br></br>
            No warranties:<br></br>
            ALL CONTENT ON THE SITE IS PROVIDED TO YOU &#39;&#39;AS IS&quot; WITHOUT WARRANTY OF ANY KIND EITHER
            EXPRESS OR IMPLIED INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY<br></br>
            &amp; FITNESS FORA PARTICULAR PURPOSE, TITLE, NON INFRINGEMENT, SECURITY OR ACCURACY. AIILSG DOES
            NOT ENDORSE &amp; IS NOT RESPONSIBLE FOR THE ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE OR
            STATEMENT MADE THROUGH THE SITE BY ANY PARTY OTHER THAN AIILSG OTHER THAN AS REQUIRED
            UNDER APPLICABLE LAW,<br></br>
            UNDER NO CIRCUMSTANCE WILL AIILSG BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A USER&#39;S
            RELIANCE ON INFORMATION OBTAINED THROUGH THE SITE. IT IS THE RESPONSIBILITY OF THE USER TO
            EVALUATE THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY OPINION, ADVICE OR OTHER CONTENT
            AVAILABLE ON/THROUGH THE SITE. PLEASE SEEK THE ADVICE OF PROFESSIONALS, AS APPROPRIATE,
            REGARDING THE EVALUATION OF ANY SPECIFIC OPINION, ADVICE OR OTHER CONTENT.<br></br>
            Privacy: Users are invited to submit comments, questions, ideas, reviews or any other information concerning urban update and
            the website&#39;s contents, provided they understand that such comments will be subject to moderation &amp;urban update reserves the
            right to remove or edit such content. All such submissions should not be illegal, obscene, threatening, defamatory or infringing
            on privacy or on intellectual property rights of others.<br></br>
            Confidentiality Of User Communications: Except as required by law, AIILSG will maintain the confidentiality of all user
            communications which contain personal user information &amp; which are transmitted directly to AIILSG. Postings by a user
            on any wessboard!9r i.n any chat rQ will not he.prot:cted as confidenP.:U &amp; AIILSG may e &amp;mpyide ill.fonnatis)n b
            HOm.Jlul11P-S:!LUrDanagCJate.IDLJ. ··News(ll_n_lgs:R_urbanugCiate.l[!lcategoryLnew tJ.
            .QQviD-1l UltiP-S;tLur a<br></br>
            contained in any such postings (including any ideas, concepts, know-how or other intellectual property) to AIILSG for any
            purpose whatsoever &amp; as deemed appropriate by AIILSG.<br></br>
            Linked Internet Sites: AIILSG prohibits caching, unauthorized hypertext links to the Site &amp; the framing of any Content available
            through the Site. AIILSG reserves the right to disable any unauthorized links or frames &amp; specifically disclaims any responsibility
            for the Content available on any other Internet sites linked to the Site. Access to any other Internet sites linked to the Site is at
            the user&#39;s own risk.<br></br>

            Postings: AIILSG is under no obligation to review any messages; information or content (&quot;Postings&quot;) posted on the Site by users
            &amp; assumes no responsibility or liability relating to any such Postings. Notwithstanding the above, AIILSG may from time to time
            monitor the Postings on the Site &amp; may decline to accept and/or remove any Postings that contain
            Any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, profane, hateful, racially, ethnically or
            otherwise objectionable material of any kind, including, but not limited to, any material which encourages conduct that would
            constitute a criminal offense, give rise to civil liability or otherwise violate any applicable local, state, national or international
            law.<br></br>
            • Advertisements or solicitations of any kind.<br></br>
            • Messages posted by users impersonating others.<br></br>
            • Personal information such as messages which state phone numbers, social security numbers, account numbers,<br></br>
            addresses, or employer references.<br></br>
            • Messages by non-spokesperson employees of AIILSG purporting to speak on behalf of AIILSG<br></br>
            • Messages that offer unauthorized downloads of any copyrighted or private information.<br></br>
            • Multiple messages placed within individual folders by the same user restating the same point.<br></br>
            • Chain letters /mails of any kind.<br></br>
            • Links to other websites:<br></br>
            This website contains links to other websites that are operated by third parties. www.urban update.in does not accept any liability
            over the content of these third party sites. The existence of these links does not constitute an endorsement of such websites, and
            your linking to these sites is at your own risk.<br></br>
            Content Accuracy: Whilst all reasonable efforts have been made to ensure the accuracy of content, noresponsibility can be taken
            for any error or omission.<br></br>
            Limitation of Liability: Urban update does not guarantee that access to this website will be uninterrupted; that this website will
            be free from viruses or that this website cannot be tampered with by third parties. Use of this website and the information is
            entirely at the user&#39;s sole risk. In no event will Urban update be liable for any damages whatsoever arising out of or related to this
            website (whether based in contract, tort, strict liability or otherwise) for any direct, indirect, incidental, consequential, or special
            damages arising out of or in any way connected with access to or use of the Site, (even if AIILSG has been advised of the
            possibility of such damages) including liability associated with any viruses which may infect a user&#39;s computer equipment.
            Modification of the Terms and Conditions: Urban update may modify the terms of this agreement on this site. Any
            modifications made will be posted on the site. You agree to periodically review this agreement to be aware of any such
            revisions. Should the revision be unacceptable to you, you agree to stop accessing this site. Your continued use of this
            Home(httgs:Liurbanugdate.in/). News(httgs:Liurbanugdate.in/category/news/). COVID-
            19(httgs:L/urba<br></br>
            website following such notification of any such revision(s) shall be deemed as acceptance of and agreement to abide by all such
            revisions.<br></br>
            Access To Password Protected/Secure Areas: Access to &amp; use of password protected and/or secure areas of the Site is restricted to
            authorized users only. Unauthorized individuals attempting to access these areas of the Site may be subject to prosecution.
            Governing Law: This agreement shall be governed by &amp; construed in accordance with the laws of India. User
            access to &amp; use of the Site is subject to all applicable laws.<br></br>
            Jurisdiction: In respect of all matters/disputes arising out of, in connection with or in relation to this agreement, only the civil
            courts at New Delhi, India shall have jurisdiction, to the exclusion of all other courts.<br></br>

            Publication: The Web site is deemed to be published from New Delhi, India (irrespective of where it is accessed from).
            Online Payments: ON LINE PAYEMENT BY USER/CUSTOMER IS SUBJECT TO THE ACCEPTANCE OF THE
            FOLLOWING TERMS AND CONDITIONS<br></br>
            This online payment system is provided by AIILSG (MERCHANT). The terms and conditions shall be amended, changed and/or
            modified from time to time by the MERCHANT and any such amendments/modifications/ changes shall be effective forthwith.
            Terms &amp; Conditions.<br></br>
            Please read these terms carefully before using the online payment facility for the Products and/or Services of MERCHANT. Using
            the online payment facility on this website indicates that you accept these terms and conditions. Ifyou do not accept these terms
            do not use this facility.<br></br>
            All payments are subject to the following conditions:<br></br>
            The Product and Services are as described in urbanupdate.in website, under various sections. Please read carefully the description<br></br>
            of the product/service before proceeding with payment.<br></br>
            Payment shall be made in advance for the Products and Services.<br></br>
            All prices quoted are in Indian Rupees, unless explicitly stated otherwise. The MERCHANT reserves the right to change the
            prices at any time.<br></br>
            Your payment will normally reach the MERCHANT account to which you are making a payment within two working days. The
            MERCHANT cannot accept liability for a payment not reaching the MERCHANT account due to you quoting an incorrect
            account number or incorrect personal details or for any other fault. The Merchant shall not accept liability if payment is refused
            or declined by the net banking system I credit/debit card supplier for any reason.
            Ifthe card supplier declines payment the MERCHANT is under no obligation to bring this fact to your notice. You should
            check with your bank/credit/debit card supplier that payment has been deducted from your account and reached the
            MERCHANT.<br></br>
            In no event , the MERCHANT shall be liable for any loss, damages, compensation whatsoever arising out of the use, inability to
            use, or the results of use of this site, any websites linked to this site, or the materials or information contained at any or all such
            sites, whether based on warranty, contract, tort or any other legal theory and whether or not advised of
            the posM trUt date.in/). News(httP-s://urbanuP-date.in/category/news/). COVID-19(httP-s:l/urba
            Refunds: Refunds, if applicable, at the discretion of the MERCHANT and shall be made only to the netbanking
            account/debit/credit card used for the original transaction. For the avoidance of doubt nothing in this Policy shall require the
            MERCHANT to refund the Fees (or part thereof) unless such Fees (or part thereof) have previously been credited to the
            MERCHANT account.<br></br>
            Communications: We are continually seeking out solutions that will bring all areas of the site up to the same level of overall
            web accessibility. In the meantime should you experience any difficulty in accessing the www.urban update.in, please don&#39;t
            hesitate to contact us.<br></br>
            All communications and notices may be sent to:<br></br>
            All India Institute of Local Self Government 22,23<br></br>
            Institutional Area, Janakpuri, D Block Pankha<br></br>
            Road, New Delhi-110058<br></br>
            Telephone : 011-28525465/ 011-28521783<br></br>
            Fax: 011-28522117<br></br>

            Email: delhi@aiilsg.org    <br></br>       
             </p>
          </div>
        </div>
      </div>
    </div>
  );
}
